<template>
    <div>
        <app-layout v-if="!formDataShow">
            <template v-slot:header>
                <Header :title="$t('leave_of_absence_title')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('leave_of_absence_title')"
                              :isFilter="false"/>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <div class="mb-4">
                        <h6 class="text-uppercase mb-0">{{ $t('student_information') }}</h6>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('student_number_long')">{{ leaveStudentData.student_number }}</b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('name_surname')">{{ this.getObjectValue(leaveStudentData, 'full_name') }}</b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('nationality')">{{ leaveStudentData.nationality }}</b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('faculty')">{{ leaveStudentData.faculty_name }}</b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('class')">{{ leaveStudentData.class }}</b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('program')">{{ leaveStudentData.program_name }}</b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('tc_or_passport')">{{ this.getObjectValue(leaveStudentData, 'national_id') }}</b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('email')">{{ this.getObjectValue(leaveStudentData, 'email') }}</b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('gsm_no')">{{ this.getObjectValue(leaveStudentData, 'mobile_tel') }}</b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('address')">{{ this.getObjectValue(leaveStudentData, 'address') }}</b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('before_leave_of_absence')">{{ leaveStudentData.before_leave_of_absence }}</b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('leave_of_absence_count')">{{ this.getObjectValue(leaveStudentData, 'leave_of_absence_count') }}</b-form-group>
                </div>
            </div>
            <b-form-group>
                <b-button variant="outline-primary" @click="openSmsVerify">{{ $t('update_info_btn') }}</b-button>
            </b-form-group>
            <hr>
            <div class="my-3 d-inline-block"></div>
            <div class="mb-4">
                <h6 class="text-uppercase mb-0">{{ $t('application_information') }}</h6>
            </div>
            <ValidationObserver ref="leaveForm">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                        <ValidationProvider name="leaveOfAbsencePeriod" rules="required" v-slot="{valid, errors}">
                            <b-form-group class="mb-0" :label="$t('leave_of_absence_period')"></b-form-group>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6" v-if="leaveStudentData.leave_of_absence_fall == 1">
                                    <b-form-group>
                                        <div>
                                            <b-form-checkbox v-model="leaveForm.period" value="1">
                                                <span>{{ $t('fall') }}</span>
                                            </b-form-checkbox>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6" v-if="leaveStudentData.leave_of_absence_spring == 1">
                                    <b-form-group v-if="leaveStudentData.leave_of_absence_spring == 1">
                                        <div>
                                            <b-form-checkbox v-model="leaveForm.period" value="2">
                                                <span>{{ $t('spring') }}</span>
                                            </b-form-checkbox>
                                        </div>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                        <ValidationProvider name="leaveOfAbsenceReason" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('leave_of_absence_reason')">
                                <parameter-selectbox v-model="leaveForm.reason"
                                                     code="leave_of_absence_reasons"
                                                     :validate-error="errors[0]"
                                                     :withoutItems="explanationWithoutItems"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                        <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('explanation')">
                                <b-form-textarea v-model="leaveForm.explanation"
                                                 no-resize
                                                 :state="errors[0] ? false : (valid ? true : null)"
                                                 rows="4"
                                                 class="h-40 h-auto-sm"
                                                 maxlength="100"></b-form-textarea>
                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                        <ValidationProvider name="excuseFile" :rules="leaveForm.reason == 'military' || leaveForm.reason == 'sickness' ? 'required' : ''" v-slot="{valid, errors}">
                            <b-form-group :label="$t('excuse_file')">
                                <div class="d-flex custom-file-upload">
                                    <b-form-file v-model="leaveForm.file"
                                                 :state="errors[0] ? false : (valid ? true : null)"
                                                 :placeholder="$t('select_file')"
                                                 :drop-placeholder="$t('drop_file')"
                                                 ref="fileInput"
                                                 multiple></b-form-file>
                                    <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}</b-button>
                                    <b-button variant="outline-secondary" class="btn-40" v-b-popover.hover.bottom="'Yükle'"><i class="ri-upload-2-line"></i></b-button>
                                </div>
                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </div>
                <b-form-group>
                    <b-button variant="primary" @click="openModalClick">{{ $t('save') }}</b-button>
                </b-form-group>
            </ValidationObserver>
            <CommonModal :size="'xl'" ref="verifyModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('attention') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <b-form-group style="white-space: pre-wrap">
                            <span v-if="studentProgramLevel == 'YL' || studentProgramLevel == 'D'">
                                {{ $t('leave_of_absence_verify_text_graduate') }}
                            </span>
                            <span v-else>
                                {{ $t('leave_of_absence_verify_text') }}
                            </span>
                        </b-form-group>
                        <div class="d-flex">
                            <b-button variant="primary" class="mr-3" @click="sendLeaveForm">
                                <b-spinner label="Spinning" class="align-middle mr-2" small v-show="updated"></b-spinner>
                                {{ $t('yes') }}
                            </b-button>
                            <b-button type="submit" variant="outline-secondary" @click="$refs.verifyModal.$refs.commonModal.hide()">{{ $t('no') }}</b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
            <CommonModal ref="gsmVerifyModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('mobile_number_update') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="mobileForm">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <ValidationProvider name="gsmNo" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('mobile_number')">
                                            <b-form-input
                                                v-mask="'(+49)-##-########'"
                                                placeholder="(+49)-##-########"
                                                v-model="gsmVerifyForm.gsmNo"
                                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between" :class="[{'mb-4' : forgotPass},{'mb-0' : forgotPass}]">
                                <b-button type="submit" variant="primary" @click="infoUpdate()" v-show="smsSendButtonStatus">{{ $t('send') }}</b-button>
                                <b-button type="submit" variant="outline-primary" @click="AddressUpdateClick" v-show="smsSendButtonStatus">{{ $t('address_update') }}</b-button>
                            </div>
                        </ValidationObserver>
                        <div class="visibility-hidden overflow-hidden h-0 fade" :class="[{'visibility-visible, h-auto' : forgotPass},{'show' : forgotPass}]">
                            <b-alert :variant="alertVariant" :show="alertShow"><span v-if="alertVariant == 'success'">{{ $t('verify_code_again_not') }}</span> <span
                                v-if="alertVariant == 'danger'">{{ $t('send_again_not') }}</span></b-alert>
                            <ValidationObserver ref="smsVerifyActionForm">
                                <div class="mb-3">
                                    <ValidationProvider name="smsVerifyCode" rules="required" v-slot="{valid, errors}">
                                        <b-form-group>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <label class="col-form-label">{{ $t('verification_code') }}</label>
                                                <div class="text-muted"><i class="ri-time-line top-plus-2 mr-1"></i>{{ timerCount }} sn</div>
                                            </div>
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="smsVerifyActionForm.verifyCode"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <b-button type="submit" variant="primary" @click="gsmVerify">{{ $t('verify') }}</b-button>
                                    <b-button type="submit" variant="outline-secondary" @click="EnterCodeAgainFunc()" v-show="smsSendAgainButtonStatus">{{ $t('send_again_btn') }}
                                    </b-button>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </template>
            </CommonModal>
            <CommonModal ref="addressModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('address_update') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="addressForm">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <ValidationProvider name="city" rules="required" v-slot="{valid, errors}">
                                        <b-form-group>
                                            <label>{{ $t('city') }}</label>
                                            <city-selectbox
                                                :validateError="errors[0]"
                                                v-model="addressForm.city"></city-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-12">
                                    <ValidationProvider name="district" rules="required" v-slot="{valid, errors}">
                                        <b-form-group>
                                            <label>{{ $t('district') }}</label>
                                            <district-outline-selectbox
                                                :validateError="errors[0]"
                                                :city_id="addressForm.city"
                                                v-model="addressForm.district"></district-outline-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-12">
                                    <ValidationProvider name="address" rules="required" v-slot="{valid, errors}">
                                        <b-form-group>
                                            <label>{{ $t('address') }}</label>
                                            <b-form-textarea no-resize
                                                             v-model="addressForm.address"
                                                             :state="errors[0] ? false : (valid ? true : null)"
                                                             rows="4"
                                                             class="h-40 h-auto-sm"
                                                             maxlength="100"></b-form-textarea>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <b-button type="submit" variant="primary" @click="addressUpdate">{{ $t('update') }}</b-button>
                            </div>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
        <app-layout v-if="formDataShow">
            <template v-slot:header>
                <Header :title="$t('leave_of_absence_title')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('leave_of_absence_title')"
                              :isFilter="false"/>
            </template>
            <div class="row">
                <div class="col-12 col-md-12 col-xl-12 col-xxl-12">
                    <b-alert show variant="danger">{{ formDataShow }}</b-alert>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout';
    import Header from '@/layouts/AppLayout/Header';
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';

    // Component
    import CommonModal from '@/components/elements/CommonModal';
    import CitySelectbox from '@/components/interactive-fields/CitySelectbox';
    import DistrictOutlineSelectbox from '@/components/interactive-fields/DistrictOutlineSelectbox'
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';

    // Services
    import LeaveOfAbsenceService from '@/services/LeaveOfAbsenceService';
    import PersonService from '@/services/PersonService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import isset from 'isset-php'

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            CitySelectbox,
            CommonModal,
            DistrictOutlineSelectbox,
            ParameterSelectbox,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                user: {},

                formDataShow: false,
                updated: false,
                leaveStudentData: {},
                periodOptions: [
                    {name: 'Güz', id: 1},
                    {name: 'Bahar', id: 2}
                ],
                leaveForm: {
                    period: [],
                    reason: null,
                    explanation: null,
                    file: null,
                    accepted: null
                },
                gsmVerifyForm: {
                    gsmNo: null
                },

                smsSendButtonStatus: true,
                smsSendAgainButtonStatus: false,
                alertVariant: 'success',
                alertShow: true,
                forgotPass: false,
                timerCount: 5,

                smsVerifyActionForm: {
                    verifyCode: null
                },

                addressForm: {
                    city: null,
                    district: null,
                    address: null
                }
            }
        },
        metaInfo() {
            return {
                title: this.$t('leave_of_absence_title')
            }
        },
        computed: {
            explanationWithoutItems() {
                let response = '';
                let user = this.$store.getters['auth/getUser'];
                if (isset(() => user.person.gender)) {
                    if (user.person.gender == 'Kadın') {
                        return 'military';
                    }
                }

                return response;
            },
            studentProgramLevel() {
                return this.$store.getters['auth/getUser']?.active_student_program?.program_level ?? '';
            }
        },

        methods: {
            getFormData() {
                LeaveOfAbsenceService.getLeaveForm()
                                     .then(response => {
                                         this.leaveStudentData = response.data.data
                                         this.gsmVerifyForm.gsmNo = this.leaveStudentData.mobile_tel
                                         this.getAddress()

                                         if(this.leaveStudentData.leave_of_absence_fall == 1) {
                                            this.leaveForm.period.push(1)
                                         }

                                         if(this.leaveStudentData.leave_of_absence_spring == 1) {
                                            this.leaveForm.period.push(2)
                                         }
                                     })
                                     .catch(e => {
                                         this.formDataShow = this.$t('api.' + e.data.message)
                                     })
            },
            getAddress() {
                PersonService.getAddress()
                             .then(response => {
                                 let data = response.data.data
                                 this.addressForm.city = data.contact_city_id
                                 this.addressForm.district = data.contact_district_id
                                 this.addressForm.address = data.contact_address
                                 this.leaveStudentData.address = data.contact_address
                                 if (data.contact_city_name) {
                                     this.leaveStudentData.address += ' ' + data.contact_city_name;
                                 }
                                 if (data.contact_district_name) {
                                     this.leaveStudentData.address += ' / ' + data.contact_district_name;
                                 }
                             })
            },

            customLabel(value) {
                return value;
            },

            async openModalClick() {
                const isValidLeave = await this.$refs.leaveForm.validate();
                if (isValidLeave) {
                    this.$refs.verifyModal.$refs.commonModal.show()
                }

            },

            async sendLeaveForm() {
                this.updated = true
                let formStatus = {
                    status: 'pending',
                    fall: 0,
                    spring: 0
                }

                this.leaveForm.period.forEach(function(val) {
                    if (val == 1) {
                        formStatus.fall = 1
                    }

                    if (val == 2) {
                        formStatus.spring = 1
                    }
                })

                let formData = new FormData()
                if (this.leaveForm.file && this.leaveForm.file.length > 0) {
                    formData.append('file', this.leaveForm.file[0])
                }
                formData.append('explanation', this.leaveForm.explanation)
                formData.append('status', formStatus.status)
                formData.append('fall', formStatus.fall)
                formData.append('spring', formStatus.spring)
                formData.append('reason', this.leaveForm.reason)
                formData.append('accepted', 1)

                LeaveOfAbsenceService.storeLeaveForm(formData).then(response => {
                    if (response.status === 200) {
                        this.$refs.verifyModal.$refs.commonModal.hide()
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$router.push('/my-requests/0') /// send to 0. tab
                    }
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$refs.verifyModal.$refs.commonModal.hide()
                            this.$refs.leaveForm.errors.leaveOfAbsencePeriod.push(this.$t('api.' + e.data.message));
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    /*
                     else if (e.status == 420){
                     this.$swal.fire({
                     text: this.$t('api.' + e.data.message),
                     showCancelButton: true,
                     confirmButtonText: this.$t('yes'),
                     cancelButtonText: this.$t('no')
                     })
                     .then((result) => {
                     this.leaveForm.accepted=0;
                     if (result.isConfirmed) {
                     this.leaveForm.accepted=1;
                     this.sendLeaveForm();
                     }
                     })
                     }
                     */
                    else {
                        if (e.data.errors.explanation) {
                            this.$refs.leaveForm.errors.explanation.push(e.data.errors.explanation[0]);
                        }
                        if (e.data.errors.fall) {
                            this.$refs.leaveForm.errors.leaveOfAbsencePeriod.push(e.data.errors.fall[0]);
                        }
                        if (e.data.errors.spring) {
                            this.$refs.leaveForm.errors.leaveOfAbsencePeriod.push(e.data.errors.spring[0]);
                        }
                        if (e.data.errors.reason) {
                            this.$refs.leaveForm.errors.leaveOfAbsenceReason.push(e.data.errors.reason[0]);
                        }
                        if (e.data.errors.student_program_id) {
                            this.$refs.leaveForm.errors.leaveOfAbsencePeriod.push(e.data.errors.student_program_id[0]);
                        }
                        if (e.data.errors.file) {
                            this.$refs.leaveForm.errors.excuseFile.push(e.data.errors.file[0]);
                        }

                        this.updated = false
                        this.$refs.verifyModal.$refs.commonModal.hide()
                    }
                })

            },

            openSmsVerify() {
                this.$refs.gsmVerifyModal.$refs.commonModal.show()
            },

            async infoUpdate() {
                const isValid = await this.$refs.mobileForm.validate();
                if (isValid) {
                    let gsmData = {
                        mobile_tel: this.gsmVerifyForm.gsmNo
                    }

                    PersonService.sendSmsCode(gsmData).then(response => {
                        let data = response.data.data;
                        this.forgotPass = true
                        this.timerCount = data.expires_in
                        this.timerEnabled = true
                        this.smsSendButtonStatus = false
                        this.smsSendAgainButtonStatus = false
                    }).catch(e => {
                       this.showErrors(e)
                    })
                }

            },

            async gsmVerify() {

                const isValid = await this.$refs.smsVerifyActionForm.validate();
                if (isValid) {
                    let smsData = {
                        sms_code: this.smsVerifyActionForm.verifyCode
                    }
                    PersonService.verifySmsCode(smsData).then(response => {

                        let data = response.data;
                        if (data.success == false) {
                            this.$refs.smsVerifyActionForm.errors.smsVerifyCode.push(data.message);
                        }
                        else {
                            this.$refs.gsmVerifyModal.$refs.commonModal.hide()
                            this.leaveStudentData.mobile_tel = this.gsmVerifyForm.gsmNo
                            this.$toast.success(this.$t('api.' + data.message));
                            this.$refs.emailVerifyModal.$refs.commonModal.show()
                        }
                    })
                }
            },

            EnterCodeAgainFunc() {
                this.alertVariant = 'success'
                this.infoUpdate()
            },

            AddressUpdateClick() {
                this.$refs.addressModal.$refs.commonModal.show()
                this.$refs.gsmVerifyModal.$refs.commonModal.hide()
            },

            async addressUpdate() {
                const isValid = await this.$refs.addressForm.validate();
                if (isValid) {
                    let addressData = {
                        contact_address: this.addressForm.address,
                        contact_city_id: this.addressForm.city,
                        contact_district_id: this.addressForm.district
                    }

                    PersonService.patchAddress(addressData)
                                 .then(response => {
                                     let data = response.data
                                     this.$toast.success(this.$t('api.' + data.message));
                                     this.$refs.addressModal.$refs.commonModal.hide()
                                     this.getAddress()
                                 })
                                 .catch(e => {
                                     if (e.status == '404') { ///406 geldiği zaman değiştirilecek
                                         if (e.data.message) {
                                             this.$refs.addressForm.errors.city.push(e.data.message);
                                         }
                                     }
                                     else {
                                         if (e.data.errors.contact_address) {
                                             this.$refs.addressForm.errors.address.push(e.data.errors.contact_address[0]);
                                         }
                                         if (e.data.errors.contact_city_id) {
                                             this.$refs.addressForm.errors.city.push(e.data.errors.contact_city_id[0]);
                                         }
                                         if (e.data.errors.contact_district_id) {
                                             this.$refs.addressForm.errors.district.push(e.data.errors.contact_district_id[0]);
                                         }
                                     }
                                 })
                }
            }
        },
        created() {
            this.getFormData()
        },

        watch: {
            timerEnabled(value) {
                this.timerCount--;
            },
            timerCount: {
                handler(value) {
                    if (value > 0 && this.timerEnabled) {
                        setTimeout(() => {
                            this.timerCount--;
                        }, 1000);
                    }
                    else if (value > 0 && !this.timerEnabled) {
                        this.alertShow = false
                        this.smsSendAgainButtonStatus = false
                    }
                    else {
                        this.alertVariant = 'danger'
                        this.alertShow = true
                        this.smsSendAgainButtonStatus = true
                    }
                },
                immediate: true
            },
            timerEnabledEmail(value) {
                this.timerCountEmail--;
            },
            timerCountEmail: {
                handler(value) {
                    if (value > 0 && this.timerEnabledEmail) {
                        setTimeout(() => {
                            this.timerCountEmail--;
                        }, 1000);
                    }
                    else if (value > 0 && !this.timerEnabledEmail) {
                        this.alertShowEmail = false
                        this.smsSendAgainButtonStatus = false
                    }
                    else {
                        this.alertVariantEmail = 'danger'
                        this.alertShowEmail = true
                        this.emailSendAgainButtonStatus = true
                    }

                },
                immediate: true
            }
        }
    };
</script>
